import quarterRange from './quarter-range';

export default function recentQuarters() {
  let periods = [];
  let currentYear = new Date().getFullYear();

  [currentYear, currentYear - 1, currentYear - 2].forEach((year) => {
    for (let i = 4; i > 0; i--) {
      const range = quarterRange(year, i);
      let [startDate, endDate] = range;
      periods.push({
        startDate,
        endDate,
        year,
        quarter: i,
      });
    }
  });

  let currentIndex = periods.findIndex((quarter) => {
    const now = new Date();
    return now >= quarter.startDate && now <= quarter.endDate;
  });

  return periods
    .filter((p) => p.startDate < new Date())
    .slice(0, Math.min(currentIndex + 4, periods.length));
}
